import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import { PaymentAPI } from '@service/payment.service';
import { IPaymentMethods } from '@models/payment-method';
import { isCardExpired } from '@utils/getCardExire';

interface IPaymentState {
    data: IPaymentMethods;
    isLoading: boolean;
}

const initialState: IPaymentState = {
    data: [],
    isLoading: false,
};

export const getPaymentMethods = createAsyncThunk('getPaymentMethods', async () => {
    try {
        const response = await PaymentAPI.getPaymentMethods();
        return response;
    } catch (error: any) {
        throw error as any;
    }
});

export const Payment = createSlice({
    name: 'payment',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPaymentMethods.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPaymentMethods.fulfilled, (state, action) => {
                // const response = omitBy(action.payload, isNil);
                state.data = action.payload;
                state.isLoading = false;
            })
            .addCase(getPaymentMethods.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const payment = (state: RootState) => state.payment;

export const selectIsPaymentFound = (state: RootState) => state.payment.data.length > 0;

export const selectPaymentMethods = (state: RootState) => {
    const cards = state.payment.data;
    return cards.map((card) => ({
        ...card,
        Expire: `${card.ExpMonth}/${card.ExpYear}`,
        Status: isCardExpired(12, 26) == true ? 'Inactive' : 'Active',
    }));
};

export const selectPaymentMethodsIsLoading = (state: RootState) => {
    return state.payment.isLoading;
};
export default Payment.reducer;
