import { RequestStatus } from '@models/async-status.enum';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';
import { isNil, omitBy } from 'lodash';
import { ISubscribePlanDetails, ISubscribePlan } from '@models/subscription.model';
import { SubscribeAPI } from '@service/subscription.service';
import { calculateTimeDifference } from '@utils/calculateDayDifference';
import { ISingleTierPayload, ITier } from "@models/billing.model";
type PricePlansLabels = {
    [key: string]: string;
};

interface ISubscribeState {
    status: RequestStatus;
    data: ISubscribePlan[];
    detailsData: ISubscribePlanDetails;
    tiers: any;
    tier: {
        isLoading: boolean,
        data: ITier | null,
    };
}

const initialState: ISubscribeState = {
    status: 'idle',
    data: [],
    detailsData: {} as ISubscribePlanDetails,
    tiers: [],
    tier: {
        isLoading: false,
        data: null,
    },
};

export const getSubscription = createAsyncThunk('getSubscription', async () => {
    try {
        const response = await SubscribeAPI.getSubscription();
        return response;
    } catch (error: any) {
        throw error as any;
    }
});

export const getTiers = createAsyncThunk('getTiers', async () => {
    try {
        const response = await SubscribeAPI.getTiers();
        return response;
    } catch (error: any) {
        throw error as any;
    }
});

export const getTier = createAsyncThunk('getTier', async (payload: ISingleTierPayload) => {
    try {
        const response = await SubscribeAPI.getTier(payload);
        return response;
    } catch (error: any) {
        throw error as any;
    }
});


export const Subscribe = createSlice({
    name: 'subscribe',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSubscription.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getSubscription.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(getSubscription.fulfilled, (state, action) => {
                const response = omitBy(action.payload, isNil);
                state.status = 'idle';
                state.detailsData = action.payload;
            })
            .addCase(getTiers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getTiers.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(getTiers.fulfilled, (state, action) => {
                const response = omitBy(action.payload, isNil);
                state.status = 'idle';
                state.data = action.payload.Tiers;
            })
            .addCase(getTier.pending, (state) => {
                state.tier.isLoading = true;
            })
            .addCase(getTier.fulfilled, (state, action) => {
                state.tier.isLoading = false;
                state.tier.data = action.payload;
            })
            .addCase(getTier.rejected, (state) => {
                state.tier.isLoading = false;
            })
    },
});

export const subscribe = (state: RootState) => state.subscribe;
export const tierData = (state: RootState) => state.subscribe.data;
export const singleTierData = (state: RootState) => state.subscribe.tier;

export const tierSelector = createSelector([tierData], (data) => {
    return data?.map((item) => ({
        value: item.ItemId,
        label: item.Name,
    }));
});

export const pricePlanLabelsSelector = (type: 'month' | 'year') => createSelector([tierData], (data) => {
    if (!data || data.length === 0) return {};
    const initialLabels = Object.keys(data[0]).filter(
        (initialLabel) =>
            initialLabel !== 'ItemId' &&
            initialLabel !== 'Name' &&
            initialLabel !== 'IsPublic' &&
            initialLabel !== 'IsActive' &&
            initialLabel !== 'Description' &&
            initialLabel !== 'Currency' &&
            !(type === 'month' && initialLabel === 'YearlyPrice') &&
            !(type === 'year' && initialLabel === 'MonthlyPrice')
    );

    const customLabelMapping = (label: string): string => {
        const labelMappings: { [key: string]: string } = {
            'Number Of Users': 'Number Of Users',
            'Number Of Invoices': 'Number Of Invoices',
            'Number Of Entities': 'Number Of Entities',
            'Number Of Devices': 'Number Of Devices',
            'Number Of Organizations': 'Number Of Organizations',
        };
        return labelMappings[label] || label;
    };

    const plansLabel: PricePlansLabels = initialLabels.reduce((labels, key) => {
        const formattedKey = key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
        labels[key] = customLabelMapping(formattedKey);

        return labels;
    }, {} as PricePlansLabels);

    return plansLabel;
});

export const plansTableDataSelector = (type: 'month' | 'year') => createSelector(
    [tierData, pricePlanLabelsSelector(type)],
    (data: ISubscribePlan[], pricePlanLabels) => {
        if (!data || data.length === 0) return [];

        const tableColumnKeys = data.map((tier) => ({
            name: tier.Name.toLowerCase().replace(/\s/g, '_'),
            tier
        }));

        return Object.keys(pricePlanLabels).map((feature, index) => {
            const row: any = {
                key: (index + 1).toString(),
                Features: pricePlanLabels[feature],
            };

            tableColumnKeys.forEach(({ name: columnKey, tier }) => {
                if ((type === 'month' && feature === 'YearlyPrice') ||
                    (type === 'year' && feature === 'MonthlyPrice')) {
                    return;
                }

                let value = tier[feature as keyof ISubscribePlan] ?? '---';
                if (feature === 'MonthlyPrice' || feature === 'YearlyPrice') {
                    value = `${value} SAR`; // Append "SAR" to the value
                }

                row[columnKey] = value;
            });

            return row;
        });
    },
);

export const selectCurrentSubscriptions = (state: RootState) => state.subscribe?.detailsData;

export const selectIsTrialSubscriptions = createSelector(
    [selectCurrentSubscriptions],
    (subscription) => {
        return subscription?.IsTrial;
    },
);

export const selectSubscriptionsExpireTime = createSelector(
    [selectCurrentSubscriptions],
    (subscription) => {
        const expireDate = subscription.Expiration;
        return calculateTimeDifference(expireDate);
    },
);

export default Subscribe.reducer;
