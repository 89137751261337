import './Subscription.scss';
import { Card, Col, Row } from 'antd';
import { CoreButton, CoreTitle } from '@packages';
import { getSubscription, subscribe } from '@store/slices/subscribeSlice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useEffect } from 'react';
import { UTCToShortDate } from '@utils/date';
import { useTranslation } from 'react-i18next';

const Subscription = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { detailsData: subscription } = useAppSelector(subscribe);
    useEffect(() => {
        dispatch(getSubscription());
    }, []);

    return (
        <div className='subscription-wrapper'>
            {!subscription?.IsTrial && (
                <>
                    <CoreTitle tag='h5' text={t('PURCHASED')} />
                    <Card className='tier-card-wrapper'>
                        <Row>
                            <Col span={18}>
                                <div className='tier-card-names'>
                                    <CoreTitle tag='h6' text={t('TIER')} />
                                    <p>{subscription?.TierName}</p>
                                </div>
                            </Col>
                            <Col span={6}>
                                <p className='tier-card-expiration-date'>
                                    {t('EXPIRES_ON')} :{' '}
                                    <span>{UTCToShortDate(subscription?.Expiration)}</span>
                                </p>
                            </Col>
                        </Row>
                    </Card>
                </>
            )}

            <div className='margin-top-24'>
                <CoreTitle tag='h5' text={t('ADD_ONS')} />
                <Card className='tier-card-wrapper'>
                    <Row>
                        <Col span={18}>
                            <div className='tier-card-names'>
                                <CoreTitle tag='h6' text={t('ORGANIZATIONS')} />
                                <p>{t('ADD_MORE_ORGANIZATION_TO_YOUT_SUBSCRIPTION')}</p>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className='tier-card-actions'>
                                <CoreButton type='basic' text={t('VIEW')} />
                            </div>
                        </Col>
                    </Row>
                </Card>
            </div>
        </div>
    );
};

export default Subscription;
