import { AxiosError } from 'axios';
import { AXIOS_INSTANCE } from '@config/axios-config';
import { APP_CONFIG } from '@config/constants';
import { ISubscribe, ISubscribePlanDetails } from '@models/subscription.model';
import { ICreateTierPayload, ISingleTierPayload, IUpdateTierPayload } from '@models/billing.model';

export const SubscribeAPI = {
    getSubscription: async (): Promise<ISubscribePlanDetails> => {
        const url = APP_CONFIG.eisUrl + `SubscriptionQuery/Subscription`;
        try {
            const response = await AXIOS_INSTANCE.get<any>(url);
            return response?.data?.Result || {};
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    addTire: async (payload: ICreateTierPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `TierCommand/CreateTier`;
        try {
            const response = await AXIOS_INSTANCE.post<ICreateTierPayload>(url, payload);
            return response;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getTiers: async (): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `TierCommand/GetTiers`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, {});
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    getTier: async (payload: ISingleTierPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `TierCommand/GetTier`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

    subscribe: async (payload: ISubscribe): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `SubscriptionCommand/Subscribe`;
        try {
            const response = await AXIOS_INSTANCE.post<any>(url, payload);
            return response?.data?.Result;
        } catch (error) {
            const err = error as AxiosError;
            throw err?.response?.data;
        }
    },

    updateTire: async (payload: IUpdateTierPayload): Promise<any> => {
        const url = APP_CONFIG.eisUrl + `TierCommand/UpdateTier`;
        try {
            const response = await AXIOS_INSTANCE.post<IUpdateTierPayload>(url, payload);
            return response;
        } catch (error) {
            const err = error as AxiosError;
            throw err;
        }
    },

};
