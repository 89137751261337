/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import './CardView.scss';
import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Spin, Tag } from 'antd';
import { PaymentAPI } from '@service/payment.service';
import { useAppDispatch } from '@store/hooks';
import { getPaymentMethods } from '@store/slices/paymentSlice';

export interface ICardViewProps {
    type: string;
    number: string;
    expiry: string;
    status?: string;
    onClick: () => void;
    selectedCardId?: string;
    cardId: string;
}

export default function CardView({
    type,
    number,
    expiry,
    status,
    onClick,
    selectedCardId,
    cardId,
}: ICardViewProps) {
    const [spinning, setSpinning] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const onRemovePayment = async () => {
        setSpinning(true);
        await PaymentAPI.removePaymentMethods(cardId)
            .then((data) => {
                setSpinning(false);
                getPayments();
            })
            .catch((error) => {
                console.log(error);
                setSpinning(false);
            });
    };

    const getPayments = () => {
        dispatch(getPaymentMethods());
    };

    const items: MenuProps['items'] = [
        {
            key: 'Upload',
            label: (
                <span onClick={onRemovePayment} aria-hidden='true'>
                    Remove
                </span>
            ),
        },
    ];

    return (
        <Spin spinning={spinning}>
            <div
                className='credit-card'
                onClick={onClick}
                style={
                    (selectedCardId &&
                        selectedCardId === cardId && { border: '2px solid #101828' }) ||
                    {}
                }
            >
                <div className='card-header'>
                    <div className='card-type'>{type}</div>
                    <div className='card-options'>
                        <Dropdown menu={{ items }} trigger={['click']}>
                            <MoreOutlined />
                        </Dropdown>
                    </div>
                </div>
                <div className='card-body'>
                    <div className='card-number'>•••• •••• •••• {number}</div>
                    <div className='card-bottom-container'>
                        <div className='card-expiry'>Expires: {expiry}</div>
                        {status && (
                            <div className='card-status'>
                                <Tag color='blue'>{status}</Tag>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Spin>
    );
}
